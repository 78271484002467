import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Helmet from "react-helmet";
const shadow = "4px 4px 5px 1px black";
function App() {
  // responsive width
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  // hubspot setup
  useEffect(() => {
    {
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region: "na1",
          portalId: "23664382",
          formId: "55447f51-56fe-460d-a660-76a15e201efe",
          target: "#form",
        });
      }
    }
  }, []);
  useEffect(() => {
    let hsButton = document.getElementsByClassName("hs-button")[0];
    if (hsButton)
      hsButton.addEventListener("click", () => {
        alert("clicked");
      });
  }, [document.getElementsByClassName("hs-button")[0]]);

  // GA setup
  function gtag(string: string, data: any) {
    (window as any).dataLayer.push(arguments);
  }
  useEffect(() => {
    (window as any).dataLayer = (window as any).dataLayer || [];

    function gtag(string: string, data: any) {
      (window as any).dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-VQ2CV2MZXV");
  }, []);
  return (
    <div className="App">
      <header className="App-header"></header>
      <body style={{ display: "grid" }}>
        {/* Splash */}
        <div
          style={{
            overflow: "hidden",
            height: "100vh",
            display: "grid",
            backgroundImage:
              "URL(https://res.cloudinary.com/drertcbvx/image/upload/f_auto/v1672769037/batchfinders/joint_sqw3lf.jpg)",
            backgroundPosition: "59% 65%",
            objectFit: "contain",
            placeContent: "center",
          }}
        >
          <h1
            style={{
              color: "white",
            }}
          >
            Say <i>Goodbye</i> To Bad Bud
          </h1>
          <a href="#waitlist">
            <button
              style={{
                padding: "1rem",
                backgroundColor: "#16701F",
                border: "none",
                color: "white",
                fontWeight: "bold",
                justifySelf: "center",
                width: "100%",
                boxShadow: shadow,
              }}
            >
              Find Out How
            </button>
          </a>
        </div>
        {/* Hello To */}
        <div
          style={{
            backgroundColor: "#16701F",
            display: "flex",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <h1 style={{ color: "white" }}>
            And <i>Hello</i> To...
          </h1>
          {/* Batchfinder */}
          <div
            style={{
              backgroundColor: "white",
              flex: 1,
              display: "grid",
              alignContent: "center",
              boxSizing: "border-box",
              padding: "5rem",
            }}
          >
            <img
              style={{ width: "100%", height: "auto" }}
              src="https://res.cloudinary.com/drertcbvx/image/upload/f_auto/v1672779376/batchfinders/Batchfinders_dpoi7o.png"
            ></img>
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gap: "5rem",
            padding: "5rem 0px 5rem 0rem",
          }}
        >
          {/* Banners */}
          <div
            style={
              width > 900
                ? {
                    backgroundColor: "#16701F",
                    textAlign: "start",
                    boxSizing: "border-box",
                    padding: "5rem",
                    display: "flex",
                    gap: "5rem",
                  }
                : {
                    backgroundColor: "#16701F",
                    textAlign: "start",
                    boxSizing: "border-box",
                    padding: "5rem",
                    display: "flex",
                    gap: "5rem",
                    flexDirection: "column",
                  }
            }
          >
            <div
              style={{
                flex: 1,
                display: "grid",

                gap: "5rem",
              }}
            >
              <img
                src="https://res.cloudinary.com/drertcbvx/image/upload/f_auto/v1672769038/batchfinders/batches_pnyez8.jpg"
                style={{ width: "100%", boxShadow: shadow }}
                alt="indoor cannabis farm"
              ></img>
            </div>
            <div style={{ display: "grid", alignContent: "center", flex: 1 }}>
              <h1 style={{ color: "white" }}>BatchData</h1>
              <p
                style={{
                  color: "white",
                  maxWidth: "50rem",
                }}
              >
                We all know how hard it is to find a reliable source for weed
                reviews. That is why we created BatchData. BatchData by
                BatchFinders is a peer-sourced platform that enables consumers
                to review cannabis products purchased from dozens of legal
                dispensaries and purchasers to find what’s best for them.
              </p>
            </div>
          </div>{" "}
          {/* Banners */}
          <div
            style={
              width > 900
                ? {
                    backgroundColor: "#16701F",
                    textAlign: "start",
                    boxSizing: "border-box",
                    padding: "5rem",
                    display: "flex",
                    gap: "5rem",
                    flexDirection: "row-reverse",
                  }
                : {
                    backgroundColor: "#16701F",
                    textAlign: "start",
                    boxSizing: "border-box",
                    padding: "5rem",
                    display: "flex",
                    gap: "5rem",
                    flexDirection: "column",
                  }
            }
          >
            <div
              style={{
                flex: 1,
                display: "grid",
                alignContent: "center",
              }}
            >
              <img
                src="https://res.cloudinary.com/drertcbvx/image/upload/f_auto/v1672769037/batchfinders/community_hbsrc3.jpg"
                style={{ width: "100%", boxShadow: shadow }}
                alt="three young adults smoking a joint together"
              ></img>
            </div>
            <div
              style={{
                flex: 1,
                display: "grid",
                alignContent: "center",
              }}
            >
              <h1
                style={{
                  color: "white",
                  wordBreak: "break-word",
                  fontSize: "56px",
                }}
              >
                Communities
              </h1>
              <p
                style={{
                  color: "white",
                  maxWidth: "50rem",
                }}
              >
                Find new friends, check out their favorite strains and let them
                know how you liked it, connect with like-minded cannabis
                enthusiasts to share what you're smoking, growing and more.
              </p>
            </div>
          </div>
          {/* Waitlist */}
          <div
            id="waitlist"
            style={
              width > 900
                ? {
                    backgroundColor: "#16701F",
                    textAlign: "start",
                    boxSizing: "border-box",
                    display: "flex",
                    padding: "5rem",
                    gap: "5rem",
                    flexDirection: "row-reverse",
                  }
                : {
                    backgroundColor: "#16701F",
                    textAlign: "start",
                    boxSizing: "border-box",
                    padding: "5rem",
                    display: "flex",
                    gap: "5rem",
                    flexDirection: "column",
                  }
            }
          >
            {/* Waitlist Copy */}
            <div
              style={{
                flex: 1,
                alignContent: "center",
                display: "grid",
              }}
            >
              <h1
                style={{
                  color: "white",
                }}
              >
                Join The Waitlist
              </h1>
              <p
                style={{
                  color: "white",
                  flex: 1,
                  alignSelf: "center",
                  maxWidth: "50rem",
                }}
              >
                Keep in touch with us! We’re working on some pretty cool things
                and would love to take you along on the journey. We promise not
                to spam you with anything or sell your information, just updates
                on our launch and any cool developments from our team.
              </p>
            </div>
            {/* Waitlist Form */}
            <div
              style={{
                flex: 1,
                backgroundColor: "white",
                display: "grid",

                padding: "5rem",
              }}
            >
              <div style={{}} id="form"></div>
            </div>
          </div>
        </div>
      </body>
      <footer className="App-footer"></footer>
    </div>
  );
}

export default App;
